import React, {Component} from "react";
import axios from "axios";
import {Link, Navigate} from "react-router-dom";
import strings from "./strings";
import AuthenticationService from './service/AuthenticationService';
import language from "./language";

interface MyState {
}

interface MyProps {
}

class Blog extends Component<MyProps, MyState> {

    state: {
        language: string;
        text: string;
        title: string;
        list: any[],
        show_blog: boolean,
        id: number,
        date: string,
        go_edit: boolean,
        go_create: boolean,
        show_all: boolean,
        blog_language: string
    }

    constructor(props: MyProps) {
        super(props)

        const lang = language()

        this.state = {
            title: '',
            text: '',
            list: [],
            date: '',
            id: 0,
            go_edit: false,
            go_create: false,
            show_all: false,
            language: lang,
            blog_language: language(),
            show_blog: false
        }

        this.post = this.post.bind(this)
        this.previous = this.previous.bind(this)
        this.next = this.next.bind(this)
        this.go_edit = this.go_edit.bind(this)
        this.go_create = this.go_create.bind(this)
        this.get_blog_list = this.get_blog_list.bind(this)
        this.logout = this.logout.bind(this)

        const params = window.location.href.split('/')
        const id = params[4];
        this.post(`${process.env.REACT_APP_API_URL}/blog/${process.env.REACT_APP_SITE_NAME}/${this.state.blog_language}/${id !== undefined ? id : ''}`)
        this.get_blog_list(lang, 10);
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    post(url: string) {
        const headers = {
            "Content-Type": "application/json",
        };

        // @ts-ignore
        axios.get(url, headers)
            .then(response => {
                    this.setState({
                        title: response.data.title,
                        text: response.data.text,
                        id: response.data.id,
                        date: response.data.date_string
                    })
                }
            )
            .catch(error => {
                console.log(error)
            });
    }

    logout() {

        AuthenticationService.logout()

        this.setState({
            logout: true
        })

        let postData = {
            request_code: 0
        };

        let axiosConfig = AuthenticationService.getAxiosConfig();

        const url = process.env.REACT_APP_API_URL + '/user/signout';

        axios.post(url,
            postData,
            axiosConfig
        )
            .then(response => {
                    this.setState({
                        logout: true
                    })
                }
            )
            .catch(error => {
                console.log(error)
            });
    }

    get_blog_list(lang: string, count: string | number) {
        const headers = {
            "Content-Type": "application/json",
        };

        const _lang = (lang === 'en' || lang === 'nl') ? lang : 'en';
        const url = process.env.REACT_APP_API_URL + "/blogs/" + process.env.REACT_APP_SITE_NAME + "/" + _lang + "/" + count

        // @ts-ignore
        axios.get(url, headers)
            .then(response => {
                    this.setState({
                        list: Array.from(response.data),
                    })
                    window.scrollTo(0, 0)
                }
            )
            .catch(error => {
                console.log(error)
            });
    }

    previous() {
        //  this.post(process.env.REACT_APP_API_URL + "/previous_blog/" + process.env.REACT_APP_SITE_NAME + "/" + this.state.blog_language + "/" + this.state.id
        this.post(`${process.env.REACT_APP_API_URL}/previous_blog/${process.env.REACT_APP_SITE_NAME}/${this.state.blog_language}/${this.state.id !== undefined ? this.state.id : ''}`)
    }

    next() {
        this.post(`${process.env.REACT_APP_API_URL}/next_blog/${process.env.REACT_APP_SITE_NAME}/${this.state.blog_language}/${this.state.id !== undefined ? this.state.id : ''}`)
    }

    go_edit() {
        this.setState({
            go_edit: true
        })
    }

    go_create() {
        this.setState({
            go_create: true
        })
    }

    blog_link(event: number) {
        this.setState({
            id: event
        })
        this.post(process.env.REACT_APP_API_URL + "/blog/" + process.env.REACT_APP_SITE_NAME + "/" + this.state.blog_language + "/" + event)
    }

    render() {

        const redirect = '/edit_blog/' + this.state.id
        if (this.state.go_edit === true) {
            return <Navigate to={redirect}/>
        }
        if (this.state.go_create === true) {
            return <Navigate to='/edit_blog/'/>
        }

        const title = this.state.title;
        const date = this.state.date;
        const text = this.state.text;
        const id = this.state.id;

        const list = this.state.list
        let blog_list: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | JSX.Element[] | null | undefined = []

        if (list !== undefined) {
            blog_list = list.map((item) =>
                <div key={item.id}>
                    <div className='blog_list_item mt-3'><Link to={'/blog/'}
                                                               onClick={() => this.blog_link(item.id)}>{item.title}</Link>
                        <div
                            className='date_string'> {(item.date_string !== undefined && item.date_string.length > 10) ? item.date_string.substring(0, 10) : ''}</div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="float-container">
                    <div className='d-block d-xl-none'>
                        <div className='float-child-left-100'>
                            <div className='home-content'>
                                <div>
                                    <button
                                        className="btn btn-link mr-3"
                                        onClick={this.previous}>
                                        Previous
                                    </button>
                                    <button
                                        className="btn btn-link "
                                        onClick={this.next}>
                                        Next
                                    </button>
                                </div>
                                <h2 className='mt-3'>{title}</h2>
                                <p className='blog-date-font'>{date} {id}</p>
                                <div className='mt-5' dangerouslySetInnerHTML={{__html: text}}/>
                            </div>
                        </div>
                    </div>

                    <div className="d-none d-xl-block">
                        <div className='float-child-left'>
                            <div className='home-content'>
                                <div>
                                    <button
                                        className="btn btn-link mr-3"
                                        onClick={this.previous}>
                                        Previous
                                    </button>
                                    <button
                                        className="btn btn-link "
                                        onClick={this.next}>
                                        Next
                                    </button>
                                </div>
                                <h2 className='mt-3'>{title}</h2>
                                <p className='blog-date-font'>{date} {id}</p>
                                <div className='mt-5' dangerouslySetInnerHTML={{__html: text}}/>
                            </div>
                        </div>
                    </div>

                    <div className="float-child-right d-none d-sm-block">
                        <div id="sidebar-wrapper">
                            <ul className="sidebar-nav-blog">
                                <li className="sidebar-brand"></li>

                                <div id='linkContainer' className='ml-3'>
                                    <div>
                                        {
                                            AuthenticationService.isAdmin() === 'true' ?
                                                <span>
                                                <button
                                                    className="btn btn-outline-success mr-3"
                                                    onClick={this.go_edit}>
                                                    Edit
                                                </button>
                                                  <button
                                                      className="btn btn-outline-success mr-3"
                                                      onClick={this.go_create}>
                                                    New
                                                  </button>
                                                    <button
                                                        className="btn btn-outline-warning"
                                                        onClick={this.logout}>
                                                            Logout
                                                        </button>
                                                </span>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div>{blog_list}</div>
                                <div className='mt-5 ml-3'>
                                    <Link to='/all_blogs'>{strings.meer}</Link>
                                </div>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Blog
